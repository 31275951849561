import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {lastValueFrom} from 'rxjs';
import {EnvConfig} from '../interfaces/env-config';
import {AuthService} from '../auth/auth.service';

declare var window: any;

@Injectable()
export class EnvironmentLoaderService {
    private envConfig: EnvConfig;
    private userData: any;

    constructor(private readonly http: HttpClient, private authService: AuthService) {
        this.authService.userData$.subscribe(data => {
            this.userData = data;
            if (this.envConfig && this.userData) {
                this.updateRealmName();
            }
        });
    }

    async loadEnvConfig(configPath: string): Promise<void> {
        this.envConfig = await lastValueFrom(this.http.get<EnvConfig>(configPath));
        window.config = this.envConfig;
        if (!window.config.REALM_NAME_1) {
            window.config.REALM_NAME_1 = this.envConfig.REALM_NAME;
        }
        if (this.userData) {
            this.updateRealmName();
        }
    }

    private updateRealmName(): void {
        if (this.userData && this.envConfig) {
            this.envConfig.REALM_NAME = this.userData.realm;
            window.config = this.envConfig;
        }
    }
}
