import {Component, OnDestroy} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {Subscription} from "rxjs";
import {AuthService} from "./auth/auth.service";
import {environment} from "../environments/environment";
import {parse} from 'bcp-47';
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    standalone: true,
    imports: [RouterOutlet]
})
export class AppComponent implements OnDestroy {
  private userSub: Subscription;
  private readonly EXISTING_LANGUAGES_IN_I18N: string [] = ['de', 'en'];

  constructor(
    private translateService: TranslateService,
    private authService: AuthService,
  ) {
    this.setDefaultLanguage();
    this.setUserLocaleLanguage();
  }

  private setDefaultLanguage() {
    const language = this.getWantedLocale(navigator.language);
    this.translateService.setDefaultLang(language);
    this.translateService.use(language);
    document.documentElement.lang = language;
  }

  private setUserLocaleLanguage() {
    this.userSub = this.authService.userData$.subscribe(user => {
      if (user) {
        const language = this.getWantedLocale(user.locale !== "" ? user.locale : navigator.language);
        this.translateService.use(language);
        document.documentElement.lang = language;
      }
    });
  }

  private getWantedLocale(locale?: string): string {
    const splitted = this.normalizeLanguageCode(locale);
    const fallBackLanguage = this.normalizeLanguageCode(environment.fallBackLanguage);

    if (!splitted || splitted.length !== 2 || !this.EXISTING_LANGUAGES_IN_I18N.includes(splitted)) {
      return this.EXISTING_LANGUAGES_IN_I18N.includes(fallBackLanguage) ? fallBackLanguage : 'de';
    }

    return splitted;
  }

  private normalizeLanguageCode(languageCode?: string): string {
    if (languageCode) {
      const normalizedCode = parse(languageCode.replace('_', '-'));
      return normalizedCode.language;
    }
    return undefined;
  }


  ngOnDestroy() {
    this.userSub.unsubscribe();
  }
}
