import {inject} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from './auth.service';
import {catchError, map, tap} from 'rxjs/operators';
import {of} from 'rxjs';



export const authGuard = () => {
    return () => {
        const authService: AuthService = inject(AuthService);
        const router: Router = inject(Router);

        if (!authService.isAuthenticatedSignal()) {
            return authService.isAuthenticated().pipe(
                catchError(() => of(false)), // do not let the user access the route if authentication can neither be confirmed nor denied
                tap(isAuthenticated => {
                    if (!isAuthenticated) {
                        void router.navigate(['login']); // redirect user to login if they're not authenticated
                    }
                })
            );
        } else {
            return true;
        }
    };
}