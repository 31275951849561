import {Routes} from "@angular/router";
import {authGuard} from "./app/auth/auth.guard";

export const routes: Routes = [{
  path: '',
  data: {state: 'main'},
  loadComponent: () => import('./app/main/main.component').then(m => m.MainComponent),
  children: [
    {
      path: 'login',
      loadComponent: () => import('./app/login/login.component').then(m => m.LoginComponent)
    },
    {
      path: 'login/master',
      loadComponent: () => import('./app/login/login.component').then(m => m.LoginComponent)
    },
    {
      path: '',
      data: {state: 'root'},
      loadComponent: () => import('./app/root/root.component').then(m => m.RootComponent),
      canActivate: [authGuard()], children: [
        {
          path: '',
          redirectTo: 'users',
          pathMatch: 'full'
        },
        {
          path: 'groups',
          children: [
            {
              path: '',
              loadComponent: () => import('./app/groups/group-overview/group-overview.component').then(m => m.GroupOverviewComponent),
            },
            {
              path: 'new',
              loadComponent: () => import('./app/groups/group-edit/group-edit.component').then(m => m.GroupEditComponent)
            },
            {
              path: ':id',
              loadComponent: () => import('./app/groups/group-edit/group-edit.component').then(m => m.GroupEditComponent)
            }
          ]
        },
        {
          path: 'events',
          loadComponent: () => import('./app/events/events-overview/events-overview.component').then(m => m.EventsOverviewComponent),
        },
        {
          path: 'import-log',
          loadComponent: () => import('./app/import-log/import-log-overview/import-log-overview.component').then(m => m.ImportLogOverviewComponent),
        },
        {
          path: 'users',
          children: [
            {
              path: '',
              loadComponent: () => import('./app/users/user-overview/user-overview.component').then(m => m.UserOverviewComponent),
            },
            {
              path: 'new',
              loadComponent: () => import('./app/users/user-edit/user-edit.component').then(m => m.UserEditComponent)
            },
            {
              path: 'roles/:id',
              loadComponent: () => import('./app/users/user-roles-overview/user-roles-overview.component').then(m => m.UserRolesOverviewComponent)
            },
            {
              path: ':id',
              loadComponent: () => import('./app/users/user-edit/user-edit.component').then(m => m.UserEditComponent)
            }
          ]
        },
        {
          path: 'clients',
          children: [
            {
              path: '',
              loadComponent: () => import('./app/clients/client-overview/client-overview.component').then(m => m.ClientOverviewComponent),
            },
            {
              path: 'new',
              loadComponent: () => import('./app/clients/client-edit/client-edit.component').then(m => m.ClientEditComponent)
            },
            {
              path: ':id',
              loadComponent: () => import('./app/clients/client-edit/client-edit.component').then(m => m.ClientEditComponent)
            }
          ]
        },
        {
          path: 'firms',
          children: [
            {
              path: '',
              loadComponent: () => import('./app/firms/firm-overview/firm-overview.component').then(m => m.FirmOverviewComponent),
            },
            {
              path: 'new',
              loadComponent: () => import('./app/firms/firm-edit/firm-edit.component').then(m => m.FirmEditComponent)
            },
            {
              path: 'new-subfirm/:id',
              loadComponent: () => import('./app/firms/firm-edit/firm-edit.component').then(m => m.FirmEditComponent)
            },
            {
              path: ':id',
              loadComponent: () => import('./app/firms/firm-edit/firm-edit.component').then(m => m.FirmEditComponent)
            }
          ]
        }
      ]
    }]
}];
