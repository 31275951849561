import {inject, Injectable, signal, WritableSignal} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable, of, ReplaySubject, throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {CookieService} from 'ngx-cookie-service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {catchError, finalize, map, tap} from 'rxjs/operators';
import {Buffer} from 'buffer';


@Injectable({
    providedIn: 'root'
})
export class AuthService {
    //Services
    private readonly translate: TranslateService = inject(TranslateService);
    private readonly http: HttpClient = inject(HttpClient);
    private readonly router: Router = inject(Router);
    private readonly toastr: ToastrService = inject(ToastrService);
    private readonly cookieService: CookieService = inject(CookieService);

    //Variables
    public outline$: ReplaySubject<any> = new ReplaySubject<any>(null);
    public userData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public username$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    private _isAuthenticatedSignal: WritableSignal<boolean> = signal(false);

    public get isAuthenticatedSignal(): WritableSignal<boolean> {
        return this._isAuthenticatedSignal;
    }


    constructor() {
        window.onstorage = (a: any) => {
            {
                if (localStorage.getItem('signOut')) {
                    void this.router.navigate(['/login']);
                }
            }
        }
    }

    public isAuthenticated(): Observable<boolean> {
        return this.getLogin().pipe(
            tap(user => {
                this.userData$.next(user);
            }),
            map(() => {
                this._isAuthenticatedSignal.set(true);
                return true;
            }),
            catchError(error => {
                this.userData$.next(undefined);
                if (error instanceof HttpErrorResponse && error.status === 401) {
                    return of(false);
                }
                return throwError(error);
            })
        );
    }

    public getOutline(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/user/${environment.realmConfig}/outline`);
    }


    public getLogin(headers?: HttpHeaders): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/token/login`, {headers: headers});
    }


    public login(username: string, password: string, realmName: string): void {
        let headers: HttpHeaders
        headers = new HttpHeaders({
            Authorization: 'Basic ' + Buffer.from(username + ':' + password,).toString('base64'),
            Realm: realmName
        });
        this.getLogin(headers).subscribe({
            next: (response: any): void => {
                this._isAuthenticatedSignal.set(true);
                this.userData$.next(response);
                localStorage.removeItem('signOut');
                void this.router.navigate(['/']);
                if (response.realm === "master") {
                    environment.realmConfig = environment.realmNames[0];
                } else {
                    environment.realmConfig = response.realm;
                }
            },
            error: (): void => {
                this._isAuthenticatedSignal.set(false);
                this.toastr.error(this.translate.instant('MESSAGES.ERROR.AUTH'), this.translate.instant('MESSAGES.ERROR.AUTH_TITLE'));
                void this.router.navigate(['/login']);
            }
        });
    }

    public logout() {
        this.http.post<any>(`${environment.apiUrl}/logout`, {}).pipe(
            finalize(() => {
                this.deleteCookies();
                localStorage.clear();
                this.outline$.next(undefined);
                localStorage.setItem('signOut', 'true');
                this.userData$.next(undefined);
                this._isAuthenticatedSignal.set(false);
                void this.router.navigate(['/login']);
            })
        ).subscribe()
    }


    public deleteCookies() {
        // es wird auf die outline subscribed, um alle cookies in den Unterpfaden zu löschen.
        // die Standardfunktion des Services deleteAll() löscht oftmals die Unterpfade nicht
        this.outline$.subscribe(outline => {
            if (outline) {
                outline.forEach(element => {
                    this.cookieService.deleteAll(`/${element.name.toLowerCase()}`);
                })
            }
        })
        this.cookieService.deleteAll();
    }
}
